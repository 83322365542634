import { Component, onMount, onCleanup } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";

export const TariffChargeAcknowledge: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  onMount(() => {
    setOrderData("selected", "tariffConsent", undefined);
  });
  onCleanup(() => {
    setOrderData("selected", "tariffConsent", undefined);
  });
  return (
    <div class="border bg-faint-blue p-3 flex items-center text-sm text-roma-dark-grey">
      <button
        aria-label={
          !orderData.selected.tariffConsent
            ? "Check freight charge consent box"
            : "Uncheck freight charge consent box"
        }
        class="ml-2 w-5 aspect-square shrink-0 flex justify-center items-center self-center"
        classList={{
          "bg-white border": !orderData.selected.tariffConsent,
          "bg-roma-blue": !!orderData.selected.tariffConsent,
        }}
        onClick={() => {
          setOrderData(
            "selected",
            "tariffConsent",
            !orderData.selected.tariffConsent
          );
        }}
      >
        <Icon path={check} class="text-white w-4" stroke-width={3} />
      </button>
      <p class="ml-4">
        As a result of your shipping selection, you agree to the additional tariff fee that will be applied to your order, estimated during checkout.
      </p>
    </div>
  );
};
